import { select } from 'd3'

function offset(el) {
  var rect = el.getBoundingClientRect(),
  scrollLeft = window.scrollX || document.documentElement.scrollLeft,
  scrollTop = window.scrollY || document.documentElement.scrollTop;
  return { top: rect.top + scrollTop, left: rect.left + scrollLeft, height: rect.height }
}

// function for hiding the tooltip
function hideTooltip() {
  select('.tooltip').classed('invisible', true)
}

// function for fading in or hiding the text on the last slide
function displayFinaleText(status) {
  if (status == 'show') {
    document.querySelector('.explore-agencies').classList.add('opacity-full')
    document.querySelector('.chart-key').classList.add('opacity-full')
    document.querySelector('.chart-search-grid').classList.add('opacity-full')
  }

   if (status == 'hide') {
    document.querySelector('.explore-agencies').classList.remove('opacity-full')
    document.querySelector('.chart-key').classList.remove('opacity-full')
    document.querySelector('.chart-search-grid').classList.remove('opacity-full')
   }
}

// setting the z-index of the chart and the scrolly scene text
// if the scenes text is above the chart, the tooltips won't work because the text
// blocks the mouse interactions
function setZIndex(status) {
  if (status == 'default') {
    document.querySelector('.scrolly-container .scenes').setAttribute('style', 'z-index: 2')
      document.querySelector('.scrolly-container .chart').setAttribute('style', 'z-index: 0')
  }

  if (status == 'finale') {
    document.querySelector('.scrolly-container .scenes').setAttribute('style', 'z-index: 0')
    document.querySelector('.scrolly-container .chart').setAttribute('style', 'z-index: 2')
  }
}

export { offset, hideTooltip, displayFinaleText, setZIndex }
